import {styled} from "@mui/material/styles";

export const StepWrapper = styled('div')(() => ({
	display: 'flex',
	marginBottom: '30px',
	justifyContent: 'center'
}))

export const StepItem = styled('div', {shouldForwardProp: (propName) => propName !== 'active'})<{
	active: boolean
}>(({theme, active}) => ({
	padding: ' 15px 10px',
	margin: '0',
	borderBottomWidth: '4px',
	borderBottomStyle: 'solid',
	borderBottomColor: active ? theme.palette.text.secondary : theme.palette.greyBlue.main,
	textAlign: 'center',
	fontSize: '12px',
	fontFamily: 'Circular',
	color: active ? theme.palette.text.secondary : theme.palette.greyBlue.main
}))
