/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, {Fragment, useContext} from 'react';
import { DialogTitle, Grid, DialogContent, DialogActions, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledButton } from "shared/ui/Button/styles";
import { BootstrapDialog, Wrapper } from "./styles";
import { ApplicationFormContext } from 'app/providers/applicationForm/lib/ApplicationFormContext';

interface Props {
	open: boolean;
	handleClose: () => void;
}

export default function DisclosuresBanner({open, handleClose}: Props) {
	const { applicationFormData } = useContext(ApplicationFormContext);
	const fraudNoticeKeys = Object.keys(applicationFormData.dynamicSchema.definitions).filter((key) => key.includes('FraudNotice'));
	const noticeOfCancelationKeys = Object.keys(applicationFormData.dynamicSchema.definitions).filter((key) => key.includes('NoticeOfCancelation'));
	const fraudNoticeData: any = applicationFormData?.dynamicSchema?.definitions[fraudNoticeKeys[0]];
	const noticeOfCancelationData: any = applicationFormData?.dynamicSchema?.definitions[noticeOfCancelationKeys[0]];

	const createMarkup = (data: any) => {
		return {__html: data.default.Value};
	}

	return (
		<Fragment>
			{open && 
				<Wrapper>
					<BootstrapDialog>
						<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
							Disclosures
						</DialogTitle>
						<IconButton
							aria-label="close"
							onClick={handleClose}
							sx={{
								position: 'absolute',
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}
						>
							<CloseIcon />
						</IconButton>
						<DialogContent dividers>
							<Typography gutterBottom variant="subtitle1">
								{fraudNoticeData?.default?.Value && 
									<div style={{'maxHeight': '90vh'}} dangerouslySetInnerHTML={createMarkup(fraudNoticeData)} />
								}
							</Typography>
							<Typography gutterBottom variant="subtitle1">
								{noticeOfCancelationData?.default?.Value && 
									<div style={{'maxHeight': '90vh'}} dangerouslySetInnerHTML={createMarkup(noticeOfCancelationData)} />
								}
							</Typography>
						</DialogContent>
						<DialogActions>
							<Grid container justifyContent={"center"}>
								<Grid item xs={6} md={6} lg={5}>
									<StyledButton
										loadingPosition="start"
										variant="outlined"
										onClick={handleClose}
									>
										CLOSE
									</StyledButton>
								</Grid>
							</Grid>
						</DialogActions>
					</BootstrapDialog>
				</Wrapper>
			}
			
		</Fragment>
	);
}