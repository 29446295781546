import React, { useContext } from 'react'
import { BorderBlock, ListElement, ListLeftText, ListRightText } from "../styles";
import { List, Typography } from "@mui/material";
import { FormDataContext } from "app/providers/formValues/lib/FormDataContext";
import { numberToCurrency } from "shared/utils/NumberToCurrency";
import dayjs from 'dayjs';
import { useQuoteQuery } from 'entities/Quote/hooks/queries';

const periodsObj: Record<number, string> = {
	1: 'Annual',
	4: '4-pay',
	11: 'Monthly'
}

const dateMask = (value: string | Date) => {
	return dayjs(value).format('MM-DD-YYYY');
};

const CoverageInfo = () => {
	const {formData, protections, policyStartDate} = useContext(FormDataContext);
	const {plan} = formData;
	const query = useQuoteQuery();
	const hurricaneDeductible = query?.coverageDetails.parameters.find((item) => item.name === 'hurricaneDeductible')?.value;
	const deductible = query?.coverageDetails.parameters.find((item) => item.name === 'deductible')?.value;
	const liability = query?.coverageDetails.parameters.find((item) => item.name === 'liabilityAmount')?.value;
	const belongings = query?.coverageDetails.parameters.find((item) => item.name === 'personalPropertyAmount')?.value;

	return (
		<BorderBlock>
			<Typography variant="h4">Coverage Info</Typography>
			<List>
				<ListElement>
					<ListLeftText>Coverage start Date</ListLeftText>
					<ListRightText>{dateMask(policyStartDate)}</ListRightText>
				</ListElement>
				<ListElement>
					<ListLeftText>Renters payment option</ListLeftText>
					<ListRightText>{periodsObj[plan.period]}</ListRightText>
				</ListElement>
				<ListElement>
					<ListLeftText>Your liability</ListLeftText>
					<ListRightText>{liability && numberToCurrency(liability)}</ListRightText>
				</ListElement>
				<ListElement>
					<ListLeftText>Your belongings</ListLeftText>
					<ListRightText>{belongings && numberToCurrency(belongings)}</ListRightText>
				</ListElement>
				<ListElement>
					<ListLeftText>Deductible</ListLeftText>
					<ListRightText>{deductible && numberToCurrency(deductible)}</ListRightText>
				</ListElement>
				<ListElement>
					<ListLeftText>Replacement cost</ListLeftText>
					<ListRightText>Included</ListRightText>
				</ListElement>
				{protections['waterBackup'] === "Y" && (
					<ListElement>
						<ListLeftText>Water sewer backup</ListLeftText>
						<ListRightText>Added</ListRightText>
					</ListElement>
				)}
				{protections['earthquake'] === "Y" && (
					<ListElement>
						<ListLeftText>Earthquake</ListLeftText>
						<ListRightText>Added</ListRightText>
					</ListElement>
				)}
				{protections['identityFraud'] === "Y" && (
					<ListElement>
						<ListLeftText>Fraud expense protection</ListLeftText>
						<ListRightText>Added</ListRightText>
					</ListElement>
				)}
				{hurricaneDeductible && (
					<ListElement>
						<ListLeftText>Hurricane Deductible</ListLeftText>
						<ListRightText>{hurricaneDeductible === "1000" ? "$1,000" : "$500"}</ListRightText>
					</ListElement>
				)}
			</List>
		</BorderBlock>
	)
}

export default CoverageInfo
