/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import { QuoteResponse, Schedule } from 'shared/api/assurant/quoteResponseTypes';
import { Plans } from 'shared/config/enums/formEnums';
import dayjs from 'dayjs';
import { Address, ApplicationAddresses } from 'shared/api/assurant/quotePayloadTypes';
import { IFormICheckoutInput } from 'shared/config/types/checkout';

interface Plan {
	period: Plans;
	price: string;
}

export const fullAddress = ({address1, address2, city, state, postalCode}: Address) => {
	if (!address1 || !city || !state && !postalCode) return '';

	const addresses = address2 ? address1.concat(', ', address2) : address1;
	const cityState = city.concat(', ', state);
	const zipCityState = cityState.concat(' ', postalCode);

	return addresses.concat(', ', zipCityState);
};

export const policyPayload = (query: QuoteResponse, data: IFormICheckoutInput, plan: Plan, applicationAddress: ApplicationAddresses) => {
	const period = plan.period;
	const paidToday = query.transactionDetails.payments.schedules.find(
		(item: Schedule) => item.numberOfPayments === period
	)?.downPaymentAmount;
	const numberOfPayment = period.toString();
	const { propertyAddress } = applicationAddress;

	const creditCardNumber = data.payment.cardNumber.replace(/\s/g, '');
	return {
		transaction: {
			workItemId: query?.transactionDetails.workItemId || ' ',
			clientId: query?.transactionDetails.clientId || 'RTW',
			referralId : query?.transactionDetails.referralId || 'Ref12345',
			jobNumber: '000932',
			productType: 'RI',
		},
		renters: {
			hasStateDisclosure: 'Y',
			hasInterestedParty: data?.interestedParty?.name ? 'Y' : 'N',
		},
		policyHolder: {
			payeeInfo: {
				firstName: data?.interestedParty?.name,
				lastName: ' ',
				address: {
					address1: data?.interestedParty?.streetAddress,
					address2: data?.interestedParty?.app,
					city: data?.interestedParty?.city,
					state: data?.interestedParty?.state,
					postalCode: data?.interestedParty?.zipcode,
				}
			},
			insured: {
				insuredDateOfBirth: dayjs(data?.dateOfBirth || null).format('YYYY/MM/DD'),
				firstName: data.firstName || ' ',
				middleInitial: ' ',
				lastName: data.lastName || ' ',
				phoneNumber: data.phoneNumber,
				canText: data.alerts ? 'Y' : 'N',
				emailAddress: data.email,
				isPaperless: data.policy ? 'Y' : 'N',
				agreedToEBC: 'Y',
				ssn: ' ',
				communityName: data.communityName || ' ',
				address: {
					address1: propertyAddress.address1,
					address2: propertyAddress.address2 || ' ',
					city: propertyAddress.city,
					state: propertyAddress.state,
					postalCode: propertyAddress.postalCode,
				},
				additionalInsured: [
					{
						firstName: data?.additionalInsured?.firstName,
						lastName: data?.additionalInsured?.lastName,
					}
				]
			},
			mailingAddress: {
				address1: data.mailingAddress.address1,
				address2: data.mailingAddress.address2,
				city: data.mailingAddress.city,
				state: data.mailingAddress.state,
				postalCode: data.mailingAddress.postalCode,
			},
			billingAddress: {
				firstName: data.billingAddress.firstName,
				middleInitial: ' ',
				lastName: data.billingAddress.lastName,
				address1: data.billingAddress.address1,
				address2: data.billingAddress.address2,
				city: data.billingAddress.city,
				state: data.billingAddress.state,
				postalCode: data.billingAddress.zip,
			}

		},
		billing: {
			paymentType: data.paymentType,
			numberOfPayments: numberOfPayment,
			paymentAmount: paidToday || ' ',
			billDate: dayjs().format('YYYY/MM/DD'),
			ccInfo: {
				cardType: creditCardType(creditCardNumber),
				cardNumber: creditCardNumber,
				cardCvc: data.payment.cardCode,
				cardExpMonth: data.payment.cardDate.slice(0, 2),
				cardExpYear: `20${data.payment.cardDate.slice(-2)}`
			},
			bankInfo: {
				routingNumber: data.payment.bankRoutingNumber,
				accountNumber: data.payment.bankAccountNumber,
			},
		},
		includeAppFile: 'Y'
	}
};

const creditCardType = (card: string) => {
	const firstTwoCharactersOfCard = card.slice(0, 2);

	if (firstTwoCharactersOfCard == '34') return 'Amex';
	else if (firstTwoCharactersOfCard == '37') return 'Amex';
	else {
		const firstLetter = firstTwoCharactersOfCard.slice(0, 1);

		if (firstLetter === '4') return 'Visa';
		if (firstLetter === '5') return 'Mast';
		if (firstLetter === '6') return 'Disc';
	}
	return 'Mast';
};

export const attributesData = (formValue: IFormICheckoutInput, quoteInfo: any, policyQuery: any, data: any, dataParams: Record<string, string | null>, query: any, paymentData: any) => {
	const schedules = query.transactionDetails.payments.schedules.map((item: any) => ({
		NumberOfPayments: item.numberOfPayments.toString(),
		Description: item.description,
		DownPaymentAmount: item.downPaymentAmount ,
		InstallmentAmount: item.installmentAmount,
		ServiceFee: item.serviceFee,
		AccountPlanCode: item.accountPlanCode,
		BillPlanCode: item.billPlanCode,
	}))
	return {
		Payment: {
			BillingAddress: {
				Address1: data.policyHolderDetails?.billingAddress?.address1,
				Address2: data.policyHolderDetails?.billingAddress?.address2,
				City: data.policyHolderDetails?.billingAddress?.city,
				State: data.policyHolderDetails?.billingAddress?.state,
				ZipCode: data.policyHolderDetails?.billingAddress?.postalCode,
				FirstName: data.policyHolderDetails?.billingAddress?.firstName || data.policyHolderDetails?.insured?.address?.firstName,
				LastName: data.policyHolderDetails?.billingAddress?.lastName || data.policyHolderDetails?.insured?.address?.lastName,
				FullAddress: `${data.policyHolderDetails?.billingAddress?.address1}, ${data.policyHolderDetails?.billingAddress?.city}, ${data.policyHolderDetails?.billingAddress?.state}, ${data.policyHolderDetails?.billingAddress?.postalCode}`,
			},
			PaymentType: data.billingDetails?.paymentType,
			NumberOfPayments: data.billingDetails?.numberOfPayments,
			PaymentAmount: data.billingDetails?.amount,
			PremiumAmount: data.premiumDetails?.premiumAmount,
			OrderID: data.billingDetails?.orderId,
			ReferenceNumber: data.billingDetails?.referenceNumber,
			PaymentAuthCode: data.billingDetails?.paymentAuthCode,
			IsPaymentSuccessful: data.billingDetails?.isPaymentSuccessful,
		},
		AdditionalInsuredPerson:  policyQuery.policyHolderDetails.insured?.additionalInsuredName.length > 0 && [
			{
				FirstName: policyQuery.policyHolderDetails.insured.additionalInsuredName[0].firstName,
				LastName: policyQuery.policyHolderDetails.insured.additionalInsuredName[0].lastName,
			}
		],
		QuoteInfo: {
			AssetAddress: {
				...quoteInfo.default.AssetAddress,
				CommunityName: policyQuery.policyHolderDetails.insured?.communityName,
			},
			CoverageStartDate: quoteInfo.default.CoverageStartDate
		},
		Landlord: {
			FullAddress: data.policyHolderDetails?.payeeInfo?.address.address1 &&
				`${data.policyHolderDetails?.payeeInfo.address.address1}, ${data.policyHolderDetails?.payeeInfo.address.city}, ${data.policyHolderDetails?.payeeInfo.address.state}, ${data.policyHolderDetails?.payeeInfo.address.zipcode}`,
			FullName: data.policyHolderDetails?.payeeInfo.firstName,
			AuthorizedParty: data.policyHolderDetails?.authorizedParty
		},
		ContactInformation: {
			PhoneNumber: data.policyHolderDetails?.insured?.phoneNumber,
			Email: data.policyHolderDetails?.insured?.emailAddress,
		},
		Policy: {
			PolicyId: policyQuery?.transactionDetails.policyId,
			PolicyEndDate: policyQuery?.transactionDetails.policyExpirationDate,
			PolicyStartDate: policyQuery?.transactionDetails.policyEffectiveDate,
			PolicyModule: data.transactionDetails.policyModule,
			PolicyTerm:data.transactionDetails.policyTerm,
		},
		MailingAddress: {
			Address1:  data.policyHolderDetails?.legalAddress?.address1 || data.policyHolderDetails?.billingAddress?.address1,
			Address2: data.policyHolderDetails?.legalAddress?.address2 || data.policyHolderDetails?.billingAddress?.address2,
			City: data.policyHolderDetails?.legalAddress?.city || data.policyHolderDetails?.billingAddress?.city,
			State: data.policyHolderDetails?.legalAddress?.state || data.policyHolderDetails?.billingAddress?.state,
			ZipCode: data.policyHolderDetails?.legalAddress?.postalCode || data.policyHolderDetails?.billingAddress?.postalCode,
			FullAddress: `${data.policyHolderDetails?.legalAddress?.address1 || data.policyHolderDetails?.billingAddress?.address1}, ${data.policyHolderDetails?.legalAddress?.city || data.policyHolderDetails?.billingAddress?.city}, ${data.policyHolderDetails?.legalAddress?.state || data.policyHolderDetails?.billingAddress?.state}, ${data.policyHolderDetails?.legalAddress?.postalCode || data.policyHolderDetails?.billingAddress?.postalCode}`,
		},
		TransactionAssurant: {
			ProductType: data.transactionDetails?.productType,
			ClientID: "RTW",
			WorkItemID: data.transactionDetails?.workItemId,
			JobNumber: "000932",
			ReferralID: data.transactionDetails?.referralId,
			LineOfBusiness: data.transactionDetails?.lineOfBusiness,
			TransactionID: data.transactionDetails?.transactionId,
			MasterCompany: data.transactionDetails?.masterCompany,
			CompanyBlock: data.transactionDetails?.companyBlock,
			IsLeaseEffectiveToday: data.transactionDetails?.isLeaseEffectiveToday,
			Schedules:schedules,
			Producer: {
				AgencyNumber: data.transactionDetails?.producer?.agencyNumber,
				AgentName: data.transactionDetails?.producer?.agentName,
			},
		},
		CoverageAssurant: {
			ReplacementCost: dataParams?.replacementCost,
			IuiType: dataParams?.iuiType,
			IuiNumberOfBenefits: dataParams?.iuiNumberOfBenefits,
			IuiBenefitAmount: dataParams?.iuiBenefitAmount,
			InternetCredit: dataParams?.internetCredit,
			LossFreeCredit: dataParams?.lossFreeCredit,
		},
		Person: {
			FullName: `${data.policyHolderDetails?.insured?.firstName} ${data.policyHolderDetails?.insured?.lastName}`,
			DateOfBirth: data.policyHolderDetails?.insured?.insuredDateOfBirth,
		},
		AdditionalInsuredAttributesAssurant: {
			CanText: data.policyHolderDetails?.insured?.canText,
			IsPaperless: data.policyHolderDetails?.insured?.isPaperless,
			AgreedToEBC: data.policyHolderDetails?.insured?.agreedToEbc,
			SSN: " ",
		},
		RentersAssurant: {
			HasInterestedParty: paymentData?.interestedParty ? 'Y' : 'N',
			HasStateDisclosure: 'Y',
		}
	}
};
