/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect, useContext, useState } from 'react'
import { Outlet, useNavigate, useLocation } from "react-router-dom";

import { Box, Container, Grid, Typography } from "@mui/material";
import { ReactComponent as Logo } from 'assets/images/moved-logo@2x.svg';

import Steps from "widgets/Steps/Steps";
import Footer from "widgets/Footer/Footer";
import { ApplicationFormContext } from 'app/providers/applicationForm/lib/ApplicationFormContext';
import { FormDataContext } from 'app/providers/formValues/lib/FormDataContext';
import { useFormMutationQuery, useApplicationFormQuery } from 'entities/ApplicationForm/hooks/queries';
import { useFormBackMutationQuery } from 'entities/ApplicationFormBack/hooks/queries';
import { useProcessMutationQuery, useApplicationProcessQuery } from 'entities/ApplicationProcess/hooks/queries';

import { NavigationBundle, NavigationContext } from 'app/providers/router/ui/NavigationContext';
import { useQuoteQuery } from 'entities/Quote/hooks/queries';
import { useApplicationDataMutationQuery } from 'entities/ApplicationData/hooks/queries';

const HomePage = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { pathname, search } = location;
	const {
		setPolicyStartDate,
		setPolicyState,
		setSchemaAddress,
		setFormId,
		formId,
		formData,
		policyStartDate,
		protections
	} = useContext(FormDataContext);
	const { setApplicationFormData } = useContext(ApplicationFormContext);
	const { mutate: fetchForm, isSuccess, isError } = useFormMutationQuery();
	const { mutate: backButton, isSuccess: backSuccess } = useFormBackMutationQuery();
	const params = new URLSearchParams(search);
	const applicationFormId = params.get('applicationFormId') || localStorage.getItem('applicationFormId');
	const client_id = params.get('client_id') || localStorage.getItem('client_id');
	const query = useApplicationFormQuery();
	const { mutate: getApplicationProcess, isSuccess: isProcessSuccess } = useProcessMutationQuery();
	const applicationProcessQuery = useApplicationProcessQuery();
	const queryQuote = useQuoteQuery();
	const { mutate: updateData, isSuccess: updateDataSuccess } = useApplicationDataMutationQuery();

	const [back, setBack] = useState(false);
	const [forward, setForward] = useState(false);

	const { from, to } = useContext<NavigationBundle>(NavigationContext);

	useEffect(() => {
		if((!applicationFormId || isError)) {
			navigate("/error");
		}
	}, [isError])

	useEffect(() => {
		if (query?.dynamicSchema) {
			const quoteKeys = Object.keys(query.dynamicSchema.definitions).filter((key) => key.includes('QuoteInfo'));
			if (quoteKeys.length) {
				const quoteData = query.dynamicSchema.definitions[quoteKeys[0]];
				const policyStartDate = quoteData.default.CoverageStartDate;
				const policyAddress = quoteData.default.AssetAddress;

				if (policyStartDate) setPolicyStartDate(policyStartDate);
				if (policyAddress.State) {
					setPolicyState(policyAddress.State);
					setSchemaAddress(policyAddress);
				}
			}
			setApplicationFormData(query);
		}
	}, [query, setApplicationFormData, setPolicyStartDate, setPolicyState, setSchemaAddress]);

	useEffect(() => {
		if (applicationFormId && !formId) {
			fetchForm(applicationFormId);
			setFormId(applicationFormId);
		}
	}, [applicationFormId, fetchForm, setFormId, formId])

	useEffect(() => {
		const applicationId = localStorage.getItem("applicationFormId");
		if (backSuccess && applicationId && back) {
			getApplicationProcess(applicationId);
		}
	}, [backSuccess]);

	useEffect(() => {
		const applicationId = localStorage.getItem("applicationFormId");
		if(isProcessSuccess && applicationId && (back || forward)) {
			if (
				applicationProcessQuery?.applicationProcessStatus === "WaitingForUserInput"
			) {
				fetchForm(applicationId);
				setBack(false);
				setForward(false);
			} else {
				setTimeout(() => {
					getApplicationProcess(applicationId);
				}, 400);
			}
		}
	}, [isProcessSuccess]);

	useEffect(() => {
		if (isSuccess && applicationFormId && client_id) {
			localStorage.setItem("applicationFormId", applicationFormId);
			localStorage.setItem("client_id", client_id);
		}
	}, [isSuccess, applicationFormId, client_id]);

	const goBack = () => {
		navigate(-1);
		setBack(true);
	}

	useEffect(() => {
		if(back) {
			if (formId) backButton(formId);
		}
	}, [back]);

	useEffect(() => {
		if (from?.pathname === "/checkout" && from.key !== "default" && to?.pathname === "/") {
			setBack(true);
		}
	}, [from, to]);

	useEffect(() => {
		if (to?.pathname === "/checkout" && to.key !== "default" && from?.pathname === "/" && applicationFormId) {
			const hurricaneDeductible = queryQuote?.coverageDetails.parameters.find((item) => item.name === 'hurricaneDeductible')?.value;

			const coverageAssurant = query && Object.keys(query.dynamicSchema.definitions).filter((key) => key.includes('CoverageAssurant'));
			const coverageAssurantData =coverageAssurant && query?.dynamicSchema.definitions[coverageAssurant[0]]?.default;

			const applicationFormData = {
				CoverageAssurant: {
					Belongings: formData.belongings,
					BillingDay: policyStartDate,
					DeductibleAssurant: formData.deductible ? `$${formData.deductible}` : coverageAssurantData.DeductibleAssurant,
					FraudExpenseProtection: !!(protections.identityFraud === "Y"),
					LiabilityAssurant: formData.liability === "100000" ?
						"$100,000" : formData.liability === "50000" ? 
							"$50,000" : formData.liability === "300000" ? 
								"$300,000" : coverageAssurantData?.LiabilityAssurant,
					SewerBackup: !!(protections.waterBackup === "Y"),
					Earthquake: !!(protections.earthquake === "Y"),
					HurricaneDeductible: hurricaneDeductible === "1000" ?
						"$1,000" : hurricaneDeductible === "500" ? "$500" : coverageAssurantData?.HurricaneDeductible,
				}
			}
			setForward(true);
			updateData({
				applicationFormId,
				data: {
					attributesData: applicationFormData
				},
			});
		}
	}, [from, to]);

	useEffect(() => {
		if(updateDataSuccess) {
			const applicationId = localStorage.getItem("applicationFormId");
			if (applicationId) getApplicationProcess(applicationId);
		}
	}, [updateDataSuccess]);

	return (
		<Container maxWidth={"xl"} >
			{query?.dynamicSchema && (
				<>
					<Grid
						container
						spacing={0}
						sx={{minHeight: '100vh'}}
					>
						<Grid item xs={12}>
							<Box sx={{textAlign: 'center', position: 'relative'}} mt={5}>
								{pathname !== '/' && <Typography
									variant="body1"
									color={"text.secondary"}
									fontSize={14}
									sx={{ mr: 1, cursor: 'pointer', position: 'absolute' }}
									onClick={goBack}
								>
									Back
								</Typography>}
								<Logo width={171} height={56}/>
							</Box>
							<Container maxWidth={"lg"} sx={{mt: 5}}>
								<Steps />
								<Outlet />
								<Footer />
							</Container>
						</Grid>
					</Grid>
				</>
			)}
		</Container>
	)
}

export default HomePage
