import {styled} from "@mui/material/styles";
import Button from "@mui/lab/LoadingButton";

export const StyledButton = styled(Button)(({ theme}) => ({
	width: '100%',
	padding: '8px',
	borderRadius: '26px',
	backgroundColor: theme.palette.text.secondary,
	position: 'relative',
	fontWeight: 700,
	fontSize: '14px',
	color: '#FFFFFF',
	boxSizing: 'border-box',
	userSelect: 'none',
	border: 'none',
	transition: 'background-color 0.3s linear, color 0.1s linear',
	"&:hover": {
		transition: 'background-color 0.3s linear, color 0.1s linear',
		backgroundColor: theme.palette.darkBlue.main,
		border: 'none',
	},
	"&:disabled": {
		color:"#FFF",
		border: 'none',
		backgroundColor: theme.palette.darkBlue.secondary,
	}
}))
